import React, { useState } from 'react';
import axios from 'axios';
import './Forms.css'; // Import your CSS file if using an external stylesheet

const Forms = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });

        // Clear any error when user starts typing
        setFormErrors({
          ...formErrors,
          [name]: ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Simple client-side validation
        const errors = {};
        for (const key in formData) {
            if (formData[key].trim() === '') {
                errors[key] = 'This field is required';
            }
        }
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return; // Prevent form submission
        }

        try {
            await axios.post('https://qrps.ca:5000/api/contact', formData);
            alert('Message sent successfully!');
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
            });
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert('Message not delivered');
        }
    };

    return (
        <>
            <section className="contact-section md:px-20">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>Looking for help? Fill the form and start a new adventure.</p>
                                <div className="single-info">
                                    <h5>Headquarters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        Eros Corporate Towers,
                                        <br /> New Delhi, 110019
                                        <br /> India
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        +1(800)265-8481
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Support</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        info@qrps.ca
                                    </p>
                                </div>
{/*                                 <div className="ab-social">
                                    <h5>Follow Us</h5>
                                    <a className="fac" href="#">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="twi" href="#">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a className="you" href="#">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                    <a className="lin" href="#">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Let’s Connect</h4>
                                <p>Fill up this form to be able to connect with us.</p>
                                <form onSubmit={handleSubmit} className="row">
                                    <div className="col-md-6">
                                        {formErrors.firstName && <span className="error">{formErrors.firstName}</span>}
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}
                                            placeholder="First Name"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        {formErrors.lastName && <span className="error">{formErrors.lastName}</span>}
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                            placeholder="Last Name"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        {formErrors.email && <span className="error">{formErrors.email}</span>}
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Email Address"
                                        />
                                        
                                    </div>
                                    <div className="col-md-6">
                                        {formErrors.phone && <span className="error">{formErrors.phone}</span>}
                                        <input
                                            type="text"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            placeholder="Phone Number"
                                        />
                                        
                                    </div>
                                    <div className="col-md-12">
                                        {formErrors.subject && <span className="error">{formErrors.subject}</span>}
                                        <input
                                            type="text"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                            placeholder="Subject"
                                        />
                                        
                                    </div>
                                    <div className="col-md-12">
                                        {formErrors.message && <span className="error">{formErrors.message}</span>}
                                        <textarea
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            placeholder="How can we help?"
                                        ></textarea>
                                        
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Send Message" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Forms;

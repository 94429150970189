import React from 'react';
import aboutThumb from '../../assets/images/generated_imageai.webp';

function AboutHomeTwo() {
    return (
        <section className="mb-0 appie-about-area bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 md:px-10 py-10">
            <div className="container mx-auto">
                <div className="appie-about-box p-4 bg-gray-100 shadow-lg rounded-lg flex flex-col md:flex-row">
                    {/* Image */}
                    <div className="w-full md:w-1/2 hidden md:block">
                        <img src={aboutThumb} alt="Barqon AI" className="w-full h-full object-cover rounded-lg" />
                    </div>
                    {/* Text */}
                    <div className="w-full md:w-1/2 flex flex-col justify-center md:pl-8">
                        <span className="block mb-2 text-lg font-semibold text-purple-600">Benefits</span>
                        <h3 className="title text-2xl font-bold mb-4 text-gray-900">
                            Make a lasting impression with Barqon.
                        </h3>
                        <p className="mb-4 text-gray-700">
                            Our platform lets customers leave SEO-optimized reviews, enhancing your Google ranking. Transform your business with our AI-driven review generator.
                        </p>
                        <div className="grid grid-cols-1 gap-6">
                            <div className="appie-about-service mt-4">
                                <div className="icon mb-2 text-purple-600">
                                    <i className="fal fa-check" />
                                </div>
                                <h4 className="title text-lg font-semibold mb-2">Simplified Process</h4>
                                <p className="text-justify text-gray-700">
                                    Customers often avoid leaving reviews due to the time and effort required. Barqon simplifies the process — just Scan, Click, and Review.
                                </p>
                            </div>
                            <div className="appie-about-service mt-4">
                                <div className="icon mb-2 text-purple-600">
                                    <i className="fal fa-check" />
                                </div>
                                <h4 className="title text-lg font-semibold mb-2">Achieve More</h4>
                                <p className="text-justify text-gray-700">
                                    Achieve Authenticity, Visibility, and Google Dominance — all in one solution.
                                </p>
                            </div>
                            <div className="appie-about-service mt-4">
                                <div className="icon mb-2 text-purple-600">
                                    <i className="fal fa-check" />
                                </div>
                                <h4 className="title text-lg font-semibold mb-2">Outshine the Competition</h4>
                                <p className="text-justify text-gray-700">
                                    Outshine the competition with SEO-enhanced customer testimonials.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutHomeTwo;

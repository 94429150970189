import React from 'react';

function ServicesHomeTwo({ className }) {
    return (
        <>
            <section className={`appie-services-2-area bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 pb-100 md:px-10 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-12 text-center">
                            <div className="appie-section-title mx-auto mt-5 ">
                                <h3 className="appie-title font-bold ">What'll you get ?  </h3>
                               
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4">
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-bolt" />
                                </div>
                                <h4 className="title">Discover Barqon</h4>
                                <p>
                                Explore our AI platform to simplify feedback collection and management for your business.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-home" />
                                </div>
                                <h4 className="title">Effortless Feedback</h4>
                                <p>
                                Effortlessly gather and manage genuine customer reviews to improve your business's reputation.


                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-link" />
                                </div>
                                <h4 className="title">Enhance Visibility</h4>
                                <p>
                                Boost your business's Google ranking and reputation with AI-driven insights.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-bell" />
                                </div>
                                <h4 className="title">Elevate Your Business</h4>
                                <p>
                                Start using Barqon today to reach new heights in your industry.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-cog" />
                                </div>
                                <h4 className="title">Customize Your Experience</h4>
                                <p>
                                Tailor review requests and responses to fit your business needs.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-lock" />
                                </div>
                                <h4 className="title">Monitor Progress</h4>
                                <p>
                                Track review metrics and ranking with our insightful dashboard.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;

import React, { useState } from 'react';
import axios from 'axios'; // Make sure to install axios with `npm install axios`
import heroThumb from '../../assets/images/qrps.png';
import shapeTen from '../../assets/images/shape/shape-10.png';
import shapeEleven from '../../assets/images/shape/shape-11.png';
import shapeTwelve from '../../assets/images/shape/shape-12.png';
import shapeNine from '../../assets/images/shape/shape-9.png';
import './HeroHomeTwo.css';

function HeroHomeTwo() {
    const [email, setEmail] = useState('');

    const handleForm = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        try {
            const response = await axios.post('https://qrps.ca:5000/api/users/submit-email', { email });
            console.log('Email submitted successfully:', response.data);
        } catch (error) {
            console.error('Error submitting email:', error);
        }
    };

    return (
        <section className="relative bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 overflow-hidden py-16 lg:py-24 md:px-10">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between p-6 lg:p-12">
                    <div className="w-full lg:w-1/2">
                        <div className="text-center lg:text-left">
                            <h1 className="p-4 text-4xl lg:text-5xl font-extrabold text-gray-900 mb-6">
                                Barqon: Elevate Your Google Rankings with AI-Driven Reviews
                            </h1>
                            <p className="text-gray-700 mb-6 leading-relaxed">
                                Are you struggling to get authentic reviews that boost your business's online presence? Discover Barqon — your ultimate partner for more than just reviews. Leverage SEO-optimized customer feedback to enhance your Google ranking.
                            </p>
                            <ul className="list-disc list-inside text-gray-700 mb-6 space-y-3">
                                <li><strong>Over 10 reviews</strong> per week on average</li>
                                <li>An increase of <strong>0.3 stars</strong> in your Google rating</li>
                                <li>Higher ranking for the <strong>keywords</strong> you select</li>
                            </ul>
                            <form
                                onSubmit={handleForm}
                                className="flex flex-wrap items-center bg-gray-50 p-4 rounded-lg shadow-md border border-gray-200"
                            >
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="info@qrps.ca"
                                    className="flex-grow min-w-0 w-full sm:w-auto px-4 py-2 border border-gray-300 rounded-lg sm:rounded-l-lg sm:rounded-r-none focus:outline-none focus:ring-2 focus:ring-pink-500"
                                    required
                                />
                                <button
                                    type="submit"
                                    className="w-full sm:w-auto px-4 py-2 mt-2 sm:mt-0 bg-pink-500 text-white rounded-lg sm:rounded-r-lg sm:rounded-l-none hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-500"
                                >
                                    <i className="fal fa-arrow-right" />
                                </button>
                            </form>

                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 mt-12 lg:mt-0 flex justify-center lg:justify-end">
                        <img src={heroThumb} alt="Hero Thumbnail" className="w-full lg:w-auto lg:h-96 rounded-lg shadow-xl transform transition-transform duration-300 hover:scale-105" />
                    </div>
                </div>
            </div>
            <div className="absolute inset-0 pointer-events-none overflow-hidden shapes-container">
                <img src={shapeNine} alt="Shape Nine" className="shape shape-9" />
                <img src={shapeTen} alt="Shape Ten" className="shape shape-10" />
                <img src={shapeEleven} alt="Shape Eleven" className="shape shape-11" />
                <img src={shapeTwelve} alt="Shape Twelve" className="shape shape-12" />
            </div>
        </section>
    );
}

export default HeroHomeTwo;
